@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('../fonts/montserrat/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Italic'), url('../fonts/montserrat/Montserrat-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin'), url('../fonts/montserrat/Montserrat-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin Italic'), url('../fonts/montserrat/Montserrat-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight'), url('../fonts/montserrat/Montserrat-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight Italic'), url('../fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light'), url('../fonts/montserrat/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light Italic'), url('../fonts/montserrat/Montserrat-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium'), url('../fonts/montserrat/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium Italic'), url('../fonts/montserrat/Montserrat-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold'), url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold Italic'), url('../fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold'), url('../fonts/montserrat/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold Italic'), url('../fonts/montserrat/Montserrat-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraBold'), url('../fonts/montserrat/Montserrat-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraBold Italic'), url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Black';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Black'), url('../fonts/montserrat/Montserrat-Black.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Black Italic'), url('../fonts/montserrat/Montserrat-BlackItalic.woff') format('woff');
}

$font_regular: 'Montserrat Regular', sans-serif, Arial;
$font_medium: 'Montserrat Medium', sans-serif, Arial;
$font_semi_bold: 'Montserrat SemiBold', sans-serif, Arial;
$font_bold: 'Montserrat Bold', sans-serif, Arial;
