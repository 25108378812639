$color_white: #FFFFFF;
$color_black: #000000;
$color_grey: #F1F1F3;
$color_dark_blue: #3C247F;
$color_light_blue: #00BEF1;
$color_yellow: #FCB514;
$color_text: #333333;
$color_darker_grey: #666666;

//INTERLNKD
$color_blue_main_bg: #1056D1;
$color_blue_main_text: #0B3D91;
$color_cyan_text: #69EBD0;
$color_cyan_light: #95F9E3;
$color_cyan_very_light: #ECF2FE;
$color_cyan_dark: #69EBD0;
$color_cyan_darker: #49D49D;
