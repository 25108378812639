@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Regular"), url("../fonts/montserrat/Montserrat-Regular.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Italic"), url("../fonts/montserrat/Montserrat-Italic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Thin"), url("../fonts/montserrat/Montserrat-Thin.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Thin Italic"), url("../fonts/montserrat/Montserrat-ThinItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraLight"), url("../fonts/montserrat/Montserrat-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Montserrat ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraLight Italic"), url("../fonts/montserrat/Montserrat-ExtraLightItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Light";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Light"), url("../fonts/montserrat/Montserrat-Light.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Light Italic"), url("../fonts/montserrat/Montserrat-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Medium"), url("../fonts/montserrat/Montserrat-Medium.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Medium Italic"), url("../fonts/montserrat/Montserrat-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat SemiBold"), url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat SemiBold Italic"), url("../fonts/montserrat/Montserrat-SemiBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Bold"), url("../fonts/montserrat/Montserrat-Bold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Bold Italic"), url("../fonts/montserrat/Montserrat-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraBold"), url("../fonts/montserrat/Montserrat-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraBold Italic"), url("../fonts/montserrat/Montserrat-ExtraBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Black";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Black"), url("../fonts/montserrat/Montserrat-Black.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Black Italic"), url("../fonts/montserrat/Montserrat-BlackItalic.woff") format("woff");
}
body.login {
  background-color: #00BEF1;
  color: #666666;
  font-size: 14px;
  font-family: "Montserrat Regular", sans-serif, Arial;
}
body.login a, body.login label {
  color: #666666 !important;
}
body.login form p:hover label, body.login form p:focus label {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  color: #00BEF1 !important;
}
body.login a:hover, body.login a:focus, body.login a:active {
  color: #000000 !important;
  outline: none;
}
body.login #login {
  width: 385px;
}
body.login h1 a {
  display: none;
  width: 206px;
  height: 118px;
  background-size: 206px 118px;
}
body.login input {
  color: #666666;
  background: #FFFFFF;
  border: 1px solid #666666;
  font-size: 14px !important;
}
body.login input:hover, body.login input:focus, body.login input:active {
  border-color: #00BEF1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body.login form .input, body.login input[type=text] {
  padding: 8px;
}
body.login.wp-core-ui .button-primary {
  background: #00BEF1;
  border-color: #FFFFFF;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-transform: uppercase;
}
body.login.wp-core-ui .button-primary:hover, body.login.wp-core-ui .button-primary:focus, body.login.wp-core-ui .button-primary:active {
  border-color: #00BEF1;
}
body.login p#backtoblog {
  display: none;
}
body.login #login_error, body.login .message {
  border-left: 0;
  padding: 16px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #00BEF1;
}
body.login #login_error {
  color: #FCB514;
}

body {
  background: #F1F1F3;
  font-family: Calibri, sans-serif, arial;
}

#wp-auth-check-wrap #wp-auth-check {
  width: 385px;
}

#adminmenu, #adminmenu .wp-submenu, #adminmenuback, #adminmenuwrap {
  background-color: #1056D1;
  background-image: linear-gradient(-180deg, #0063A3 0%, #00497F 100%);
}

#adminmenu li.wp-menu-separator {
  background: #00BEF1 none repeat scroll 0 0;
  cursor: inherit;
  height: 1px;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}

.wp-admin #wpadminbar #wp-admin-bar-site-name > .ab-item::before {
  content: none;
}

#wpadminbar, #wpadminbar .ab-sub-wrapper, #wpadminbar ul, #wpadminbar ul li, #wpadminbar .ab-top-menu > li.menupop.hover > .ab-item,
#wpadminbar .ab-top-menu > li.menupop.hover > .ab-item, #wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus, #wpadminbar.nojs .ab-top-menu > li.menupop:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item, #wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus, #adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow, #adminmenu .wp-menu-arrow div, #adminmenu li.current a.menu-top, #adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top, .folded #adminmenu li.wp-has-current-submenu {
  background: #1056D1;
}

#wpadminbar .ab-top-menu > li.hover > .ab-item, #wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus, #wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus, #wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover > a, #wpadminbar .quicklinks .menupop ul li a:focus,
#wpadminbar .quicklinks .menupop ul li a:focus strong, #wpadminbar .quicklinks .menupop ul li a:hover, #wpadminbar .quicklinks .menupop ul li a:hover strong,
#wpadminbar .quicklinks .menupop.hover ul li a:focus, #wpadminbar .quicklinks .menupop.hover ul li a:hover, #wpadminbar li #adminbarsearch.adminbar-focused::before,
#wpadminbar li .ab-item:focus::before, #wpadminbar li a:focus .ab-icon::before, #wpadminbar li.hover .ab-icon::before, #wpadminbar li.hover .ab-item::before,
#wpadminbar li:hover #adminbarsearch::before, #wpadminbar li:hover .ab-icon::before, #wpadminbar li:hover .ab-item::before, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:focus,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover {
  background: none;
  color: #FCB514;
}

#adminmenu .wp-submenu a:focus, #adminmenu .wp-submenu a:hover, #adminmenu a:hover, #adminmenu li.menu-top > a:focus,
#adminmenu li.menu-top:hover, #adminmenu li.opensub > a.menu-top, #adminmenu li > a.menu-top:focus,
#adminmenu li a:focus div.wp-menu-image::before, #adminmenu li.opensub div.wp-menu-image::before, #adminmenu li:hover div.wp-menu-image::before,
#adminmenu .current div.wp-menu-image::before, #adminmenu .wp-has-current-submenu div.wp-menu-image::before, #adminmenu a.current:hover div.wp-menu-image::before,
#adminmenu a.wp-has-current-submenu:hover div.wp-menu-image::before, #adminmenu li.wp-has-current-submenu a:focus div.wp-menu-image::before,
#adminmenu li.wp-has-current-submenu.opensub div.wp-menu-image::before, #adminmenu li.wp-has-current-submenu:hover div.wp-menu-image::before,
#collapse-menu:hover, #collapse-menu:hover #collapse-button div::after {
  color: #FCB514;
}

#adminmenu li a:focus div.wp-menu-image::before, #adminmenu li.opensub div.wp-menu-image::before,
#adminmenu li:hover div.wp-menu-image::before, #adminmenu .current div.wp-menu-image::before, #adminmenu .wp-has-current-submenu div.wp-menu-image::before,
#adminmenu a.current:hover div.wp-menu-image::before, #adminmenu a.wp-has-current-submenu:hover div.wp-menu-image::before {
  color: #FCB514;
}

#wpadminbar, #adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head, #adminmenu .wp-menu-arrow, #adminmenu .wp-menu-arrow div, #adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu, .folded #adminmenu li.current.menu-top, .folded #adminmenu li.wp-has-current-submenu {
  background-image: linear-gradient(-180deg, #0063A3 0%, #00497F 100%);
}

#adminmenu .wp-submenu a:focus, #adminmenu .wp-submenu a:hover, #adminmenu li.menu-top:hover, #adminmenu li.opensub > a.menu-top, #adminmenu li > a.menu-top:focus {
  background-color: #1056D1;
}

#wpadminbar .ab-top-menu > li.hover > .ab-item, #wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus, #wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus {
  background: inherit;
}

#adminmenu li.menu-top:hover, #adminmenu li.opensub > a.menu-top, #adminmenu li > a.menu-top:focus {
  background-image: linear-gradient(-180deg, #0063A3 0%, #00497F 100%);
  color: #FCB514;
  position: relative;
}

#adminmenu li.wp-has-submenu.wp-not-current-submenu.opensub:hover::after {
  border-right-color: #FFFFFF;
}

#wp-admin-bar-my-account > .ab-item > .avatar.photo {
  display: none !important;
}

#dashboard-widgets .postbox-container {
  width: 100% !important;
}

#update-nag, .update-nag {
  display: none;
}

/** FORM ELEMENTS **/
input, input[type=text], input[type=search], input[type=radio], input[type=tel], input[type=time], input[type=url], input[type=week],
input[type=password], input[type=checkbox], input[type=color], input[type=date], input[type=datetime], input[type=datetime-local],
input[type=email], input[type=month], input[type=number], select, textarea,
.tablenav .tablenav-pages a {
  background: #FFFFFF;
  border: 1px solid #666666;
  color: #666666;
}

input:hover, input:focus, input:active,
textarea:hover, textarea:focus, textarea:active,
select:hover, select:focus, select:active {
  border-color: #00BEF1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.wp-core-ui .button-primary,
.page-title-action,
.page-title-action:hover,
.page-title-action:focus,
.page-title-action:active {
  background: #00BEF1;
  border-color: #FFFFFF;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-transform: uppercase;
}

.wp-core-ui .button, .wp-core-ui .button-secondary {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-transform: uppercase;
}
.wp-core-ui .button:hover, .wp-core-ui .button:focus, .wp-core-ui .button:active, .wp-core-ui .button-secondary:hover, .wp-core-ui .button-secondary:focus, .wp-core-ui .button-secondary:active {
  color: #FFFFFF;
  border-color: #00BEF1;
  background: #00BEF1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.wp-core-ui .button-primary:hover, .wp-core-ui .button-primary:focus, .wp-core-ui .button-primary:active {
  border-color: #FCB514;
  background: #FCB514;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input[type=checkbox]:checked::before {
  color: #FCB514;
}

input[type=text]:focus, input[type=search]:focus, input[type=radio]:focus, input[type=tel]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, input[type=password]:focus, input[type=checkbox]:focus, input[type=color]:focus, input[type=date]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, select:focus, textarea:focus {
  color: #666666;
  border-color: #00BEF1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.wrap .add-new-h2, .wrap .page-title-action, .wrap .add-new-h2,
.wrap .page-title-action, .tablenav .tablenav-pages a, .tablenav .tablenav-pages a, .tablenav .tablenav-pages a {
  color: #FFFFFF;
  border-color: #00BEF1;
  background: #00BEF1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.wrap .add-new-h2:hover, .wrap .add-new-h2:focus, .wrap .add-new-h2:active, .wrap .page-title-action:hover, .wrap .page-title-action:focus, .wrap .page-title-action:active, .wrap .add-new-h2:hover, .wrap .add-new-h2:focus, .wrap .add-new-h2:active,
.wrap .page-title-action:hover,
.wrap .page-title-action:focus,
.wrap .page-title-action:active, .tablenav .tablenav-pages a:hover, .tablenav .tablenav-pages a:focus, .tablenav .tablenav-pages a:active, .tablenav .tablenav-pages a:hover, .tablenav .tablenav-pages a:focus, .tablenav .tablenav-pages a:active, .tablenav .tablenav-pages a:hover, .tablenav .tablenav-pages a:focus, .tablenav .tablenav-pages a:active {
  border-color: #FCB514;
  background: #FCB514;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.wp-core-ui .button.button-primary.button-hero {
  box-shadow: none;
}